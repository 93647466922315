import { render, staticRenderFns } from "./batch-edit.vue?vue&type=template&id=70a6a765&scoped=true&"
import script from "./batch-edit.ts?vue&type=script&lang=ts&"
export * from "./batch-edit.ts?vue&type=script&lang=ts&"
import style0 from "./batch-edit.scoped.scss?vue&type=style&index=0&id=70a6a765&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70a6a765",
  null
  
)

export default component.exports